import { useEffect, useState } from "react";
import { ProgressBar } from 'react-bootstrap';
import { toast } from "react-toastify";
import axios from "axios";
import $ from "jquery";

const New = () => {
    const [pensioner_type, setPensionerType] = useState([]);
    const getPensionerType = async () => {
        await axios.get("/pensioner_types").then(res => {
            setPensionerType(res.data);
        });
    }
    const [file, setFile] = useState(null);
    const [upPercent, setUpPercent] = useState(0)
    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent <= 100) {
                setUpPercent(percent)
                setTimeout(() => {
                    setUpPercent(0)
                    document.getElementById('submit').disabled = false
                }, 1000)
            }
        }
    }
    useEffect(() => {
        getPensionerType();
    }, [file]);

    return (
        <div className="page-content">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="row mt-4 px-3">
                        <div className="col-md-6">
                            <h5 className="fs-20 text-black">تۆمارکردنی خانەنشینێکی نوێ</h5>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <button className="btn btn-light mx-2" type="button" data-bs-toggle="modal" data-bs-target="#groupSetting">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2" /><polyline points="2 17 12 22 22 17" /><polyline points="2 12 12 17 22 12" /></svg>
                                <span className="ms-2">جۆری هەژمار</span>
                            </button>
                            <div className="modal fade" id="groupSetting" tabIndex={-1} aria-labelledby="groupSetting" aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">ڕێکخستنی جۆری هەژمار</h5>
                                        </div>
                                        <form className="form-sample">
                                            <div className="modal-body">
                                                <div className="mb-3">
                                                    <table className="table table-striped table-sm dt-tbl">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>جۆر</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {pensioner_type && pensioner_type.map((item, index) => (
                                                                <tr key={item.id}>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        <gname onDoubleClick={(e) => { $(e.target).hide(); $(e.target).next().show(); }} data-bs-toggle="tooltip" data-bs-placement="top" title="دەبل کلیک بۆ دەستکاریکردن"> {item.pensioner_type} </gname>
                                                                        <ginput style={{ display: "none" }}>
                                                                            <input type="text" className="form-control" defaultValue={item.pensioner_type} onKeyPress={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    e.preventDefault();
                                                                                    axios.put(`/pensioner_types/${item.id}`, { pensioner_type: e.target.value }).then(res => {
                                                                                        if (res.data !== 'done') {
                                                                                            toast.error(res.data, {
                                                                                                position: "top-left",
                                                                                                autoClose: 3000,
                                                                                                hideProgressBar: false,
                                                                                                closeOnClick: true,
                                                                                                pauseOnHover: true,
                                                                                                draggable: true,
                                                                                                progress: undefined,
                                                                                            });
                                                                                        } else {
                                                                                            $(e.target).parent().hide();
                                                                                            $(e.target).parent().prev().show();
                                                                                            setPensionerType([])
                                                                                            getPensionerType();
                                                                                        }
                                                                                    });
                                                                                }
                                                                            }} data-bs-toggle="tooltip" data-bs-placement="top" title="ئینتەر داگرە بۆ پاشەکەوت کردن" />
                                                                        </ginput>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="row mt-5">
                                                    <div className="mb-3">
                                                        <label htmlFor="pensioner_types" className="form-label">جۆر</label>
                                                        <input type="text" className="form-control" id="pensioner_types" name="pensioner_types" placeholder="ناوی بەرهەم " />
                                                    </div>
                                                    <div className="mb-3">
                                                        <button type="button" className="btn btn-primary" onClick={(e) => {
                                                            e.preventDefault();
                                                            axios.post('/pensioner_types', { pensioner_type: $('#pensioner_types').val() }).then(res => {
                                                                if (res.data !== 'done') {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    setPensionerType([])
                                                                    getPensionerType();
                                                                }
                                                            });
                                                        }}>
                                                            زیادکردن
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form id="newForm" onSubmit={(e) => {
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const data = Object.fromEntries(formData.entries());
                            axios.post("/pensioners", data).then((res) => {
                                if (res.data !== "done") {
                                    toast.error(res.data, { position: toast.POSITION.TOP_LEFT })
                                }
                                else {
                                    toast.success("بەسەرکەوتویی زیادکرا")
                                    e.target.reset();
                                }
                            }).catch((err) => {
                                toast.error(err.response.data.message);
                            });
                        }}>
                            <div className="row mb-3">
                                <label htmlFor="pensioner_type_id" className="col-sm-2 col-form-label">جۆر</label>
                                <div className="col-sm-10">
                                    <select className="form-select" name="pensioner_type_id" required>
                                        {pensioner_type && pensioner_type.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.pensioner_type}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="pensioner_code" className="col-sm-2 col-form-label">کۆدی هەژمار</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="pensioner_code" placeholder="کۆدی هەژمار ..." required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="fullName" className="col-sm-2 col-form-label">ناوی سیانی</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="fullName" onChange={(e) => {
                                         $("#displayname").val(e.target.value)
                                        }
                                    } placeholder="ناوی سیانی ..." required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="fullName" className="col-sm-2 col-form-label">ناوی سەرچەک</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="displayname" name="displayname" placeholder="ناوی سەرچەک" required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="phone" className="col-sm-2 col-form-label">ژ. مۆبایل</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="phone" placeholder="ژ.مۆبایل ..." required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="address" className="col-sm-2 col-form-label">ناونیشان</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="address" placeholder="ناونیشان ..." required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="amount" className="col-sm-2 col-form-label">موچە</label>
                                <div className="col-sm-10">
                                    <input type="number" className="form-control" name="amount" placeholder="0.00 د.ع" required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="attachment" className="col-sm-2 col-form-label"> هاوپێچ</label>
                                <div className="col-sm-10">
                                    <input type="file" className="form-control" onChange={(e) => {
                                        document.getElementById('submit').disabled = true
                                        e.preventDefault();
                                        const formData = new FormData();
                                        formData.append("file", e.target.files[0]);
                                        axios.post("/upload", formData, options).then((res) => {
                                            setFile(res.data[0].filename);
                                        }).catch((err) => {
                                            toast.error(err.response.data.message)
                                        });
                                    }}
                                    />
                                    <input type="hidden" name="attachment" value={file} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-2 col-form-label" />
                                <div className="col-sm-10">
                                    {upPercent > 0 && <ProgressBar now={upPercent} active label={`${upPercent}%`} />}
                                </div>
                            </div>
                            <div className="mb-3 ltr">
                                <button type="submit" id="submit" className="btn btn-primary me-2">پێشکەشکردن</button>
                                <button className="btn btn-secondary" onClick={() => { document.getElementById('newForm').reset(); setFile(null); }}>هەڵوەشاندنەوە</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default New;