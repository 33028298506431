import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const Modify = () => {
    const [data, setData] = useState({});
    const GetData = async () => {
        await axios.get('/user').then(res => {
            setData(res.data);
        });
    }
    useEffect(() => {
        GetData();
    }, []);

    return (
        <div className="page-content">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-header d-sm-flex d-block border-0 pb-3">
                        <h5 className="fs-20 text-black">دەستکاریکردنی زانیاریی</h5>
                    </div>
                    <div className="card-body">
                        <form id="newForm" onSubmit={(e) => {
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const data = Object.fromEntries(formData.entries());
                            axios.put("/user", data).then((res) => {
                                res.data !== "done" ? toast.error(res.data, { position: toast.POSITION.TOP_LEFT }) : toast.success("بەسەرکەوتویی نوێکرایەوە")
                            }).catch((err) => {
                                toast.error(err.response.data.message);
                            });
                        }}>
                            <div className="row mb-3">
                                <label htmlFor="fullName" className="col-sm-2 col-form-label">ناوی سیانی</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="fullName" placeholder="ناوی سیانی ..." defaultValue={data.fullName} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="username" className="col-sm-2 col-form-label">ناوی بەکارهێنەر</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="username" placeholder="ناوی بەکارهێنەر" defaultValue={data.username} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="address" className="col-sm-2 col-form-label">ووشەی نهێنی</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="password" />
                                </div>
                            </div>
                            <div className="mb-3 ltr">
                                <button type="submit" id="submit" className="btn btn-primary me-2">نوێکردنەوە</button>
                                <Link to="/" className="btn btn-secondary">گەڕانەوە</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Modify;