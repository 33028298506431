import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Siebar = () => {
    const [data, setData] = useState({});
    const GetData = async () => {
        await axios.get("/auth").then(res => {
            if (res.data === "err") {
                window.location.assign("/login");
            } else {
                axios.get('/user').then(res => {
                    setData(res.data);
                });
            }
        });

    }

    useEffect(() => {
        GetData();
    }, []);

    const logout = () => {
        axios.post('/logout').then((ok) => {
            if (ok.data !== 'err') {
                window.location.assign("/login")
            } else {
                window.location.reload()
            }
        })
    }

    return (
        <div className="horizontal-menu">
            <nav className="navbar top-navbar">
                <div className="container">
                    <div className="navbar-content">
                        <a href="/" className="navbar-brand">
                            خانەنشین <span> &nbsp; سندوقی </span>
                        </a>
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <p className="nav-link dropdown-toggle" id="profileDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                                </p>
                                <div className="dropdown-menu p-0" aria-labelledby="profileDropdown">
                                    <div className="d-flex flex-column align-items-center border-bottom px-5 py-3">
                                        <div className="mb-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                                        </div>
                                        <div className="text-center">
                                            <p className="tx-16 fw-bolder">{data.fullName}</p>
                                            <p className="tx-12 text-muted">@{data.username}</p>
                                        </div>
                                    </div>
                                    <ul className="list-unstyled p-1">
                                        <li className="dropdown-item py-2">
                                            <Link to="/modify" className="text-body ms-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx="8.5" cy={7} r={4} /><polyline points="17 11 19 13 23 9" /></svg>
                                                <span className="mx-2">دەستکاریکردن</span>
                                            </Link>
                                        </li>
                                        <li className="dropdown-item py-2">
                                            <a href="/#0" className="text-body ms-0" onClick={logout}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1={21} y1={12} x2={9} y2={12} /></svg>
                                                <span className="mx-2">دەرچوون</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg>
                        </button>
                    </div>
                </div>
            </nav>
            <nav className="bottom-navbar">
                <div className="container">
                    <ul className="nav page-navigation">
                        <li className="nav-item">
                            <Link to="/" className="nav-link">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                                <span className="menu-title">دەستپێک</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/list" className="nav-link">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx={9} cy={7} r={4} /><path d="M23 21v-2a4 4 0 0 0-3-3.87" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                                <span className="menu-title"> بەشداربووان</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/checks" className="nav-link" >
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-credit-card"><rect x={1} y={4} width={22} height={16} rx={2} ry={2} /><line x1={1} y1={10} x2={23} y2={10} /></svg>
                                <span className="menu-title">چەکی بانکی</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/checking" className="nav-link" >
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-check-square"><polyline points="9 11 12 14 22 4" /><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" /></svg>
                                <span className="menu-title">چێککردن</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/new" className="nav-link" >
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx="8.5" cy={7} r={4} /><line x1={20} y1={8} x2={20} y2={14} /><line x1={23} y1={11} x2={17} y2={11} /></svg>
                                <span className="menu-title">بەشداربووی نوێ</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/logs" className="nav-link">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" /><polyline points="14 2 14 8 20 8" /><line x1={16} y1={13} x2={8} y2={13} /><line x1={16} y1={17} x2={8} y2={17} /><polyline points="10 9 9 9 8 9" /></svg>
                                <span className="menu-title">تۆمار</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default Siebar;