const Footer = () => {
    return (
        <footer className="footer border-top">
            <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between py-3 small">
                <p className="text-muted mb-1 mb-md-0">Copyright © 2023.</p>
                <p className="text-muted">Developed By <a href="/" target="_blank" rel="noreferrer">Sam & Dana</a></p>
            </div>
        </footer>
    );
}

export default Footer;