import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import commaNumber from "comma-number";
import axios from "axios";
import $ from 'jquery';

const Profile = () => {
    const { id } = useParams()
    const [pensioner, setPensioner] = useState({});
    const GetPensioner = async () => {
        await axios.get(`/pensioners/${id}`).then(res => {
            setPensioner(res.data);
        });
    }

    const [cheques, setCheques] = useState([])
    const GetCheques = async () => {
        const res = await axios.get(`/cheques/${id}`);
        setCheques(res.data);
        if (res.data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    window.$('.dt-table').DataTable();
                }
            });
            $('.py-4').hide();
            $('.dt-table').show();
        } else {
            $('.py-4').hide();
            $('.dt-table').show();
        }
    }

    useEffect(() => {
        GetPensioner();
        GetCheques();
    }, [])

    return (
        <div className="page-content">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="card-body">
                                    <p className="text-muted mb-1">کۆدی خانەنشینکراو</p>
                                    <h5 className="mb-3">{pensioner.pensioner_code}</h5>
                                    <p className="text-muted mb-1">ناوی خانەنشینکراو</p>
                                    <h5 className="mb-3">{pensioner.fullName}</h5>
                                    <p className="text-muted mb-1">ژمارەی مۆبایل</p>
                                    <h5 className="mb-3">{pensioner.phone}</h5>
                                    <p className="text-muted mb-1">ناونیشان </p>
                                    <h5 className="mb-3">{pensioner.address}</h5>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="card-body">
                                    <div className="d-flex flex-column align-items-center text-center">
                                        <p className="text-muted mb-2"> هاوپێچ</p>
                                        {pensioner.attachment ? (
                                            pensioner.attachment.split('.').pop() === 'jpg' || pensioner.attachment.split('.').pop() === 'png' || pensioner.attachment.split('.').pop() === 'jpeg' ? (
                                                <img src={`/uploads/${pensioner.attachment}`} alt={pensioner.fullName} className="rounded p-1" width="150" />
                                            ) : (
                                                <a href={`/uploads/${pensioner.attachment}`} className="rounded p-1" target="_blank" rel="noreferrer">بینین</a>
                                            )
                                        ) : (
                                            <span className="rounded p-1">  هاوپێچ نییە</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header d-block border-0 pb-3">
                            <div className="mt-3 mb-3">
                                <span className="fs-12">لیستی ئەو چەکانەی کە بە <strong>{pensioner.fullName}</strong> بەخشراوە.</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div className="container py-4">
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>
                                                    <div className="skeleton_wave" />
                                                </th>
                                                <th>
                                                    <div className="skeleton_wave" /> </th>
                                                <th><div className="skeleton_wave" /></th>
                                                <th><div className="skeleton_wave" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <table className="table dt-table" style={{ display: "none" }}>
                                    <thead>
                                        <tr>
                                            <th>ژمارە</th>
                                            <th>بۆ بەڕێز</th>
                                            <th>بڕی پارە</th>
                                            <th>لەڕێککەوتی</th>
                                            <th>چاپکردن</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cheques && cheques.map((cheque, index) => (
                                            <tr key={index}>
                                                <td>{cheque.no}</td>
                                                <td>{cheque.fullName}</td>
                                                <td>{commaNumber(cheque.amount)} د.ع</td>
                                                <td>{new Date(cheque.date).toLocaleDateString('ar-iq')}</td>
                                                <td>
                                                    <a href={`/print/${cheque.id}`} target={'_blank'} className="dropdown-item">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Profile;