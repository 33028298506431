import axios from 'axios';
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import $ from 'jquery';
import commaNumber from 'comma-number';

const List = () => {
    const [data, setData] = useState([]);
    const GetData = async () => {
        const res = await axios.get('/pensioners');
        setData(res.data);
        if (res.data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    window.$('.dt-table').DataTable();
                }
            });
            $('.py-4').hide();
            $('.dt-table').show();
        } else {
            $('.py-4').hide();
            $('.dt-table').show();
        }
    }

    useEffect(() => {
        GetData();
    }, []);

    const Delete = async (id, name) => {
        const res = await axios.delete(`/pensioners/${id}?fullName=${name}`);
        if (res.data !== "done") {
            toast.error(res.data, { position: toast.POSITION.TOP_LEFT })
        } else {
            toast.success("بەسەرکەوتویی سڕایەوە")
            GetData();
        }
    }

    return (
        <div className="page-content">
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-header d-sm-flex d-block border-0 pb-3">
                            <h5 className="fs-20 text-black">لیستی خانەنشینەکان</h5>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div className="container py-4">
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>
                                                    <div className="skeleton_wave" />
                                                </th>
                                                <th>
                                                    <div className="skeleton_wave" /> </th>
                                                <th><div className="skeleton_wave" /></th>
                                                <th><div className="skeleton_wave" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <table className="table dt-table" style={{ display: "none" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>جۆر</th>
                                            <th>کۆد</th>
                                            <th>ناو</th>
                                            <th>ناوی سەرچەک</th>
                                            <th>ژ.مۆبایل</th>
                                            <th>ناونیشان</th>
                                            <th>موچە</th>
                                            <th>بژاردە</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.map((item, index) => (
                                            <tr key={item.id} >
                                                <td>{index + 1}</td>
                                                <td>{item.pensioner_type}</td>
                                                <td>{item.pensioner_code}</td>
                                                <td>{item.fullName}</td>
                                                <td>{item.displayname}</td>
                                                <td>{item.phone}</td>
                                                <td>{item.address}</td>
                                                <td>{commaNumber(item.amount)} د.ع</td>
                                                <td>
                                                    <Link to={`/profile/${item.id}`} className="btn btn-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" /><circle cx={12} cy={12} r={3} /></svg>
                                                    </Link>
                                                    <Link to={`/edit/${item.id}`} className="btn btn-icon mx-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                    </Link>
                                                    <button type="button" className="btn btn-sm btn-outline-danger" title="Delete" onClick={() => {
                                                        Swal.fire({
                                                            title: 'Are you sure?',
                                                            text: "You won't be able to revert this!",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Yes, delete it!'
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/pensioners/${item.id}?fullName=${item.fullName}`).then(() => { 
                                                                    GetData();
                                                                    Swal.fire(
                                                                        'Deleted!',
                                                                        'Selected Data has been deleted.',
                                                                        'success'
                                                                    )
                                                                });
                                                            }
                                                        });
                                                    }}><i className="fa fa-trash-o" /></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default List;