import { createBrowserRouter } from "react-router-dom";
import ErrorPage from './Components/Pages/404';
import Siebar from './Components/Layout/Sidebar';
import Footer from './Components/Layout/Footer';
import Dashboard from './Components/Pages/Dashboard';
import New from './Components/Pensioners/New';
import List from './Components/Pensioners/List';
import Logs from './Components/Logs/Logs';
import Edit from './Components/Pensioners/Edit';
import Profile from './Components/Pensioners/Profile';
import Checks from './Components/Checks/Checks';
import Checking from './Components/Checks/Checking';
import Modify from './Components/Accounts/Modify';
import Print from "./Components/Checks/Print";
import Login from "./Components/Pages/Login";

const router = createBrowserRouter([
    {
        path: "/login", element:
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <Login />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Dashboard />
                    <Footer />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/new", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <New />
                    <Footer />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/edit/:id", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Edit />
                    <Footer />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/list", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <List />
                    <Footer />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/profile/:id", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Profile />
                    <Footer />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/checks", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Checks />
                    <Footer />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/checking", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Checking />
                    <Footer />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/print/:id", element:
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <Print />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/logs", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Logs />
                    <Footer />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/modify", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Modify />
                    <Footer />
                </div>
            </div>, errorElement: <ErrorPage />,
    },


]);

export default router;