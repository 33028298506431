import { useState, useEffect } from "react";
import axios from "axios";
import commaNumber from "comma-number";
import { Link } from "react-router-dom";

function Dashboard() {
  const [data, setData] = useState({});
  const GetStats = async () => {
    const res = await axios.get("/stats");
    setData(res.data);
  };
  useEffect(() => {
    GetStats();
  }, []);

  return (
    <div className="page-content">
      <div className="container">
        <section id="our-stats">
          <div className="row mb-5">
            <div className="col text-center">
              <h2 className="text-primary h1 text-center">سەرژمێریی گشتی</h2>
            </div>
          </div>
          <div className="row text-center">
            <div className="col">
              <Link to="/checks">
                <div className="counter ">
                  <svg xmlns="http://www.w3.org/2000/svg" width={65} height={65} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-credit-card"><rect x={1} y={4} width={22} height={16} rx={2} ry={2} /><line x1={1} y1={10} x2={23} y2={10} /></svg>
                  <h2 className="timer count-title count-number mt-3">{data ? commaNumber(data.checks) : 0}</h2>
                  <p className="count-text ">چەک</p>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link to="/list">
                <div className="counter">
                  <svg xmlns="http://www.w3.org/2000/svg" width={65} height={65} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                  <h2 className="timer count-title count-number mt-3">{data ? commaNumber(data.pensioners) : 0}</h2>
                  <p className="count-text ">خانەنشینکراو</p>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link to="/logs">
                <div className="counter">
                  <svg xmlns="http://www.w3.org/2000/svg" width={65} height={65} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-radio"><circle cx={12} cy={12} r={2} /><path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14" /></svg>
                  <h2 className="timer count-title count-number mt-3">{data ? commaNumber(data.logs) : 0}</h2>
                  <p className="count-text ">تۆمار</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
      </div >
    </div >
  );
}

export default Dashboard;
