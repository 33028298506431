import React, { useEffect, useState } from "react";
import axios from 'axios';
import Select from 'react-select';
import { toast } from "react-toastify";
import $ from 'jquery';

const Checking = () => {

    const [pensioners, setPensioners] = useState([])
    const GetPensioners = async () => {
        const res = await axios.get('/pensioners-lawer');
        setPensioners(res.data);
    }
    const [pensionerOther, setPensionerOther] = useState([]);

    useEffect(() => {
        GetPensioners();
    }, [])

    return (
        <div className="page-content">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-header d-sm-flex d-block border-0 pb-3">
                        <h5 className="fs-20 text-black">پێدانی چەکی بانکی</h5>
                    </div>
                    <div className="card-body">
                        <form id="newForm" onSubmit={(e) => {
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const data = Object.fromEntries(formData.entries());
                            axios.post("/checking", data).then((res) => {
                                if (res.data !== "done") {
                                    toast.error(res.data, { position: toast.POSITION.TOP_LEFT })
                                }
                                else {
                                    toast.success("بەسەرکەوتویی چێکی بۆ دروستکرا")
                                    e.target.reset();
                                }
                            }).catch((err) => {
                                toast.error(err.response.data.message);
                            });
                        }}>
                            <div className="row mb-3">
                                <label htmlFor="pensioner" className="col-sm-2 col-form-label">لیستی هەژمارەکان</label>
                                <div className="col-sm-10">
                                    <Select options={pensioners ? pensioners.map(pensioner => {
                                        return { value: pensioner.id, label: `${pensioner.fullName} - ${pensioner.pensioner_code} - (${pensioner.pensioner_type})`, pensioner_code: pensioner.pensioner_code }
                                    }) : { value: null, label: 'هیچ خانەنشینێک نەدۆزرایەوە !' }}
                                        onChange={(e) => {
                                            axios.get(`/pensioners-code/${e.pensioner_code}`).then(res => {
                                                setPensionerOther(res.data);
                                            })
                                        }} placeholder="لیستی خانەنشینکراوەکان" required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="pensioner_id" className="col-sm-2 col-form-label">پێدانی چەک بە </label>
                                <div className="col-sm-10">
                                    <select className="form-select" name="pensioner_id" required
                                        onChange={(e) => {
                                            $("#amount").val(e.target.options[e.target.selectedIndex].getAttribute('amount'));
                                        }}>
                                        <option value="">.....</option>
                                        {pensionerOther && pensionerOther.map((item, index) => {
                                            return (
                                                <option key={index} amount={item.amount} value={item.id}>{`${item.fullName} - ${item.pensioner_code} - (${item.pensioner_type})`}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="amount" className="col-sm-2 col-form-label">بڕی پارە (د.ع)</label>
                                <div className="col-sm-10">
                                    <input type="number" className="form-control" id="amount" placeholder="0.00 د.ع" required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="date" className="col-sm-2 col-form-label">  کاتی پێدانی چەک</label>
                                <div className="col-sm-10">
                                    <input type="date" className="form-control" name="date" defaultValue={new Date().toISOString().split('T')[0]} required />
                                </div>
                            </div>
                            <div className="mb-3 ltr">
                                <button type="submit" className="btn btn-primary me-2">چاپکردن</button>
                                <button className="btn btn-secondary" onClick={() => { document.getElementById('newForm').reset(); }}>بەتاڵکردنەوە</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Checking;