import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ProgressBar } from 'react-bootstrap';
import { toast } from "react-toastify";
import axios from "axios";

const Edit = () => {
    const [pensioner_type, setPensionerType] = useState([]);
    const getPensionerType = async () => {
        await axios.get("/pensioner_types").then(res => {
            setPensionerType(res.data);
        });
    }
    const { id } = useParams()
    const [data, setData] = useState({});
    const GetData = async () => {
        await axios.get(`/pensioners/${id}`).then(res => {
            setData(res.data);
        });
    }

    const [file, setFile] = useState(null);
    const [upPercent, setUpPercent] = useState(0)
    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent <= 100) {
                setUpPercent(percent)
                setTimeout(() => {
                    setUpPercent(0)
                    document.getElementById('submit').disabled = false
                }, 1000)
            }
        }
    }

    useEffect(() => {
        GetData();
        getPensionerType();
    }, [file]);

    return (
        <div className="page-content">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-header d-sm-flex d-block border-0 pb-3">
                        <h5 className="fs-20 text-black">دەستکاریکردنی زانیاریی</h5>
                    </div>
                    <div className="card-body">
                        <form id="newForm" onSubmit={(e) => {
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const data = Object.fromEntries(formData.entries());
                            axios.put(`/pensioners/${id}`, data).then((res) => {
                                res.data !== "done" ? toast.error(res.data, { position: toast.POSITION.TOP_LEFT }) : toast.success("بەسەرکەوتویی نوێکرایەوە")
                            }).catch((err) => {
                                toast.error(err.response.data.message);
                            });
                        }}>
                            <div className="row mb-3">
                                <label htmlFor="pensioner_type_id" className="col-sm-2 col-form-label">جۆر</label>
                                <div className="col-sm-10">
                                    <select className="form-select" name="pensioner_type_id" defaultValue={data.pensioner_type_id} required>
                                        <option value={data.pensioner_type_id}>{data.pensioner_type}</option>
                                        {pensioner_type && pensioner_type.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.pensioner_type}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="pensioner_code" className="col-sm-2 col-form-label">کۆدی هەژمار</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="pensioner_code" placeholder="کۆدی هەژمار ..." defaultValue={data.pensioner_code} required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="fullName" className="col-sm-2 col-form-label">ناوی سیانی</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="fullName" placeholder="ناوی سیانی ..." defaultValue={data.fullName} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="fullName" className="col-sm-2 col-form-label">ناوی سەرچەک</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="displayname" name="displayname" defaultValue={data.displayname} placeholder="ناوی سەرچەک" required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="mobile" className="col-sm-2 col-form-label">ژ. مۆبایل</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="phone" placeholder="ژ.مۆبایل ..." defaultValue={data.phone} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="address" className="col-sm-2 col-form-label">ناونیشان</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="address" placeholder="ناونیشان ..." defaultValue={data.address} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="amount" className="col-sm-2 col-form-label">موچە</label>
                                <div className="col-sm-10">
                                    <input type="number" className="form-control" name="amount" defaultValue={data.amount} required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="attachment" className="col-sm-2 col-form-label"> هاوپێچ</label>
                                <div className="col-sm-10">
                                    <input type="file" className="form-control" onChange={(e) => {
                                        document.getElementById('submit').disabled = true
                                        e.preventDefault();
                                        const formData = new FormData();
                                        formData.append("file", e.target.files[0]);
                                        axios.post("/upload", formData, options).then((res) => {
                                            setFile(res.data[0].filename);
                                        }).catch((err) => {
                                            toast.error(err.response.data.message)
                                        });
                                    }}
                                    />
                                    <input type="hidden" name="attachment" value={file ? file : data.attachment} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-2 col-form-label" />
                                <div className="col-sm-10">
                                    {upPercent > 0 && <ProgressBar now={upPercent} active label={`${upPercent}%`} />}
                                </div>
                            </div>
                            <div className="mb-3 ltr">
                                <button type="submit" id="submit" className="btn btn-primary me-2">نوێکردنەوە</button>
                                <Link to="/list" className="btn btn-secondary">گەڕانەوە بۆ لیست</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Edit;