import axios from 'axios';
import { useState, useEffect } from 'react'
import $ from 'jquery';

const Logs = () => {
    const [data, setData] = useState([]);
    const GetData = async () => {
        const res = await axios.get('/log');
        setData(res.data);
        if (res.data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    window.$('.dt-table').DataTable();
                }
            });
            $('.py-4').hide();
            $('.dt-table').show();
        } else {
            $('.py-4').hide();
            $('.dt-table').show();
        }
    }

    useEffect(() => {
        GetData();
    }, []);

    return (
        <div className="page-content">
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-header d-sm-flex d-block border-0 pb-3">
                            <h5 className="fs-20 text-black">لیستی تۆمار</h5>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div className="container py-4">
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>
                                                    <div className="skeleton_wave" />
                                                </th>
                                                <th>
                                                    <div className="skeleton_wave" /> </th>
                                                <th><div className="skeleton_wave" /></th>
                                                <th><div className="skeleton_wave" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <table className="table dt-table" style={{ display: "none" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>وردەکاریی</th>
                                            <th>لەلایەن</th>
                                            <th>بۆ</th>
                                            <th>کات</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.map((item, index) => (
                                            <tr key={item.id} >
                                                <td>{index + 1}</td>
                                                <td>{item.action}</td>
                                                <td>{item.account}</td>
                                                <td>{item.pensioner}</td>
                                                <td>{new Date(item.created).toLocaleString()}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Logs;