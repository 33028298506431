import React, { useEffect, useState } from "react";
import commaNumber from "comma-number";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import $ from "jquery";
import KurdishSoraniNumbersToWords from 'kurdish-numbers-to-words/Sorani.js'

const Print = () => {
    const { id } = useParams()

    const tokurdNo = function (string) {
        return string.replace(/[0-9]/g, function (c) {
            return "٠١٢٣٤٥٦٧٨٩".charAt(c);
        })
    }

    const [fullName, setFullName] = useState('')
    const [amount, setAmount] = useState('')
    const [date, setDate] = useState('')
    const [year, setYear] = useState('')
    const [amountInWords, setAmountInWords] = useState('')
    const GetCheques = async () => {
        const res = await axios.get(`/cheque/${id}`);
        console.log(res.data[0]);
        setFullName(res.data[0].displayname)
        setAmount(tokurdNo(commaNumber(res.data[0].amount).toString()))
        setDate(res.data[0].date)
        let num = res.data[0].date.split('-')[0]
        num = num.toString().substr(-2)
        setYear(num)
        let numberWordsSorani = KurdishSoraniNumbersToWords.convert(res.data[0].amount)
        setAmountInWords(numberWordsSorani)

    }

    useEffect(() => {
        GetCheques();
        setTimeout(() => {
             print()
        }, 1000);
    }, [])

    function print() {
        $(".horizontal-menu").hide();
        $(".action-btn").hide();
        $(".footer").hide();
        window.print();
        //window.location.href = "/checks";
    }

    return (
        <div className="page-content font-8">
            <div className="row" id="print">
                <div className="col-8" id="print-me">
                    <div className="row">
                        <div className="col-12 mt-2">
                            <div className="row">
                                <div className="col-12 ">
                                    <div className="row mt-4">
                                        <div className="col-3">&nbsp;</div>
                                        <div className="col-3">
                                            <div className="date-area h6" style={{ marginRight: 20, marginTop: '-11px' }}>
                                                <span className="str" style={{ right: 30, position: 'absolute' }}>هەولێر</span>
                                                <span className="day" style={{ marginRight: 5 }}>
                                                    {new Date(date).toLocaleDateString().split('/')[1]}
                                                </span>
                                                <span className="month mx-4" style={{ position: 'relative', paddingRight: 12 }}>
                                                    {new Date(date).toLocaleDateString().split('/')[0]}
                                                </span>
                                                <span className="year" style={{ marginRight: '-12px' }}>
                                                    {year}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-3">&nbsp;</div>
                                        <div className="col-3">&nbsp;</div>
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div className="row">
                                        <div className="col-3" />
                                        <div className="col-8" style={{ marginTop: '-20px' }}>
                                            <div className="mr-40 mb-2 mt-5 tx-12">{fullName}</div>
                                            <div className="mr-40 mb-2 tx-12" style={{ marginTop: '-3px' }}>{amountInWords} {'دینار تەنها'}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <div className="row">
                                        <div className="col-6" />
                                        <div className="col-6" style={{ position: 'absolute', left: '-90px', top: 147 }}>
                                            <span className="amount h5">{amount}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row">
                        <div className="col-12 mt-5">
                            <div className="row">
                                <div className="col-12 mt-2">
                                    <div className="row" style={{}}>
                                        <div className="col-3" />
                                        <div className="col-6" style={{ marginTop: '-18px', right: 120, position: 'relative' }}>
                                            <div className="mb-2">{new Date(date).toLocaleDateString('ar-iq')}</div>
                                            <div className="mb-2" style={{ left: 40, position: 'relative' }}>{fullName}</div>
                                            <div className="mb-2" style={{ top: '-2px', left: 17, position: 'relative' }}>{amount} مووچە</div>
                                        </div>
                                        <div className="col-3" />
                                    </div>
                                    <div className="row">
                                        <div className="col-3" />
                                        <div className="col-6">
                                            <div className="mb-2" style={{ position: 'relative', top: 65, right: 100, fontSize: '8px' }}> {amountInWords} </div>
                                        </div>
                                        <div className="col-3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Print;