import React, { useEffect, useState } from "react";
import commaNumber from "comma-number";
import axios from "axios";
import $ from 'jquery';
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const Checks = () => {
    const [cheques, setCheques] = useState([])
    const GetCheques = async () => {
        const res = await axios.get('/cheques');
        setCheques(res.data);
        if (res.data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    window.$('.dt-table').DataTable({
                        "order": [[0, 'desc']]

                    });
                }
            });
            $('.py-4').hide();
            $('.dt-table').show();
        } else {
            $('.py-4').hide();
            $('.dt-table').show();
        }
    }
    useEffect(() => {
        GetCheques();
    }, [])

    return (
        <div className="page-content">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header d-sm-flex d-block border-0 pb-3">
                            <div>
                                <h5 className="fs-20 mb-1 text-black">لیستی چەکەکان </h5>
                                <span className="fs-12">لیستی چەکە بانکیەکانی خانەنیشنانی پارێزەرانی کوردستان</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div className="container py-4">
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>
                                                    <div className="skeleton_wave" />
                                                </th>
                                                <th>
                                                    <div className="skeleton_wave" /> </th>
                                                <th><div className="skeleton_wave" /></th>
                                                <th><div className="skeleton_wave" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                                <td><div className="skeleton_wave rounded" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <table className="table dt-table" style={{ display: "none" }}>
                                    <thead>
                                        <tr>
                                            <th>ژمارە</th>
                                            <th>بۆ بەڕێز</th>
                                            <th>ناوی سەرچەک</th>
                                            <th>کۆدی هەژمار</th>
                                            <th>بڕی پارە</th>
                                            <th>لەڕێککەوتی</th>
                                            <th>چاپکردن</th>
                                            <th>سڕینەوە</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cheques && cheques.map((cheque, index) => (
                                            <tr key={cheque.id}>
                                                <td>{cheque.id}</td>
                                                <td>{cheque.fullName}</td>
                                                <td>{cheque.displayname}</td>
                                                <td>{cheque.pensioner_code}</td>
                                                <td>{commaNumber(cheque.amount)} د.ع</td>
                                                <td>{new Date(cheque.date).toLocaleDateString('ar-iq')}</td>
                                                <td>
                                                    <a href={`/print/${cheque.id}`} target={'_blank'} className="dropdown-item">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                                                    </a>
                                                </td>
                                                <td>
                                                    <span type="button" className="badge bg-danger" onClick={() => {
                                                        Swal.fire({
                                                            title: 'ئاگادارکردنەوە',
                                                            text: 'دڵنیای لە سڕینەوەی ئەم چەکە ؟',
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'بەڵێ',
                                                            cancelButtonText: `نەخێر`
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/delete/${cheque.id}`).then(res => {
                                                                    !isNaN(res.data) ? GetCheques() : toast.error(res.data);
                                                                })
                                                            }
                                                        })
                                                    }} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Checks;